var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "NewUser", fluid: "", tag: "section" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { staticClass: "v-card--material px-5 py-3" },
                [
                  _c("card-heading", {
                    attrs: { title: "Create User", color: "secondary" },
                  }),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-title", [
                                        _vm._v("Personal Info:"),
                                      ]),
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "text",
                                          autocomplete: "one-time-code",
                                          label: "Email",
                                          rules: [
                                            _vm.rules.required,
                                            _vm.rules.email,
                                          ],
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function ($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email",
                                        },
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "First Name",
                                          autocomplete: "one-time-code",
                                          rules: [_vm.rules.required],
                                        },
                                        model: {
                                          value: _vm.firstName,
                                          callback: function ($$v) {
                                            _vm.firstName = $$v
                                          },
                                          expression: "firstName",
                                        },
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Last Name",
                                          autocomplete: "one-time-code",
                                          rules: [_vm.rules.required],
                                        },
                                        model: {
                                          value: _vm.lastName,
                                          callback: function ($$v) {
                                            _vm.lastName = $$v
                                          },
                                          expression: "lastName",
                                        },
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Phone Number",
                                          autocomplete: "one-time-code",
                                          rules: [_vm.rules.required],
                                        },
                                        model: {
                                          value: _vm.phoneNumber,
                                          callback: function ($$v) {
                                            _vm.phoneNumber = $$v
                                          },
                                          expression: "phoneNumber",
                                        },
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          autocomplete: "one-time-code",
                                          "append-icon": _vm.show1
                                            ? "mdi-eye-off"
                                            : "mdi-eye",
                                          rules: [_vm.rules.min],
                                          type: _vm.show1 ? "text" : "password",
                                          label: "Password",
                                          counter: "",
                                        },
                                        on: {
                                          "click:append": function ($event) {
                                            _vm.show1 = !_vm.show1
                                          },
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function ($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password",
                                        },
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          "append-icon": _vm.show2
                                            ? "mdi-eye-off"
                                            : "mdi-eye",
                                          rules: [_vm.rules.passwordMatch],
                                          type: _vm.show2 ? "text" : "password",
                                          label: "Confirm Password",
                                          autocomplete: "one-time-code",
                                          counter: "",
                                        },
                                        on: {
                                          "click:append": function ($event) {
                                            _vm.show2 = !_vm.show2
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c("v-card-title", [
                                        _vm._v("Allowed Casinos:"),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex" },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.allCasinos,
                                              "item-text": "website",
                                              "item-value": "casino_id",
                                              multiple: "",
                                              chips: "",
                                              "persistent-hint": "",
                                              "hide-no-data": "",
                                              "small-chips": "",
                                              clearable: "",
                                              "clear-icon":
                                                "mdi-close primary--text",
                                              "deletable-chips": "",
                                              "menu-props": { offsetY: true },
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item",
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass:
                                                          "text-body-2 d-flex justify-space-between",
                                                        attrs: { dense: "" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.website
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("v-chip", [
                                                          _vm._v(
                                                            "ID: " +
                                                              _vm._s(
                                                                item.casino_id
                                                              )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "selection",
                                                fn: function ({ item, index }) {
                                                  return [
                                                    _vm.noShorten ||
                                                    index < _vm.maxChips
                                                      ? _c(
                                                          "v-chip",
                                                          {
                                                            key: `gameKey-${item.casino_id}`,
                                                            attrs: {
                                                              small: "",
                                                              close: "",
                                                            },
                                                            on: {
                                                              "click:close":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.removeElement(
                                                                    index
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.website
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.noShorten &&
                                                    index === _vm.maxChips
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "grey--text text-caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " (+" +
                                                                _vm._s(
                                                                  _vm
                                                                    .selected_Casinos
                                                                    .length -
                                                                    _vm.maxChips
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .selected_Casinos
                                                                    .length -
                                                                    _vm.maxChips >
                                                                    1
                                                                    ? "others"
                                                                    : "other"
                                                                ) +
                                                                ") "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "prepend-item",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "ripple",
                                                            rawName: "v-ripple",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "selectall px-3 text-body-2 d-flex align-center justify-space-between",
                                                        class:
                                                          _vm.selected_Casinos
                                                            .length ===
                                                          _vm.allCasinos.length
                                                            ? ""
                                                            : "primary--text",
                                                        on: {
                                                          mousedown: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                          },
                                                          click:
                                                            _vm.toggleAllCasinos,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .selected_Casinos
                                                                .length ===
                                                                _vm.allCasinos
                                                                  .length
                                                                ? "Deselect All"
                                                                : "Select All"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value: _vm.selected_Casinos,
                                              callback: function ($$v) {
                                                _vm.selected_Casinos = $$v
                                              },
                                              expression: "selected_Casinos",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("v-card-title", [
                                        _vm._v("Allowed Sections:"),
                                      ]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("user-edit-sections", {
                                            attrs: { readonly: false },
                                            on: {
                                              change: (newVal) => {
                                                this.selected_Sections = newVal
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.onSubmit },
                            },
                            [_vm._v(" Submit ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }