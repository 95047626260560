<template>
	<v-container
		id="NewUser"
		fluid
		tag="section">
		<v-row dense>
			<v-col>
				<v-card class="v-card--material px-5 py-3">
					<card-heading
						title="Create User"
						color="secondary"></card-heading>
					<v-card-text>
						<v-form
							ref="form"
							v-model="valid">
							<v-row>
								<v-col>
									<v-card>
										<v-card-title>Personal Info:</v-card-title>
										<v-text-field
											type="text"
											autocomplete="one-time-code"
											label="Email"
											v-model="email"
											:rules="[rules.required, rules.email]"></v-text-field>
										<v-text-field
											label="First Name"
											autocomplete="one-time-code"
											v-model="firstName"
											:rules="[rules.required]"></v-text-field>
										<v-text-field
											label="Last Name"
											autocomplete="one-time-code"
											v-model="lastName"
											:rules="[rules.required]"></v-text-field>
										<v-text-field
											label="Phone Number"
											autocomplete="one-time-code"
											v-model="phoneNumber"
											:rules="[rules.required]"></v-text-field>
										<v-text-field
											v-model="password"
											autocomplete="one-time-code"
											:append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
											:rules="[rules.min]"
											:type="show1 ? 'text' : 'password'"
											label="Password"
											counter
											@click:append="show1 = !show1" />
										<v-text-field
											:append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
											:rules="[rules.passwordMatch]"
											:type="show2 ? 'text' : 'password'"
											label="Confirm Password"
											autocomplete="one-time-code"
											counter
											@click:append="show2 = !show2" />
									</v-card>
								</v-col>
								<v-col>
									<v-card flat>
										<v-card-title>Allowed Casinos:</v-card-title>
										<div class="d-flex">
											<v-autocomplete
												v-model="selected_Casinos"
												:items="allCasinos"
												item-text="website"
												item-value="casino_id"
												multiple
												chips
												persistent-hint
												hide-no-data
												small-chips
												clearable
												clear-icon="mdi-close primary--text"
												deletable-chips
												:menu-props="{ offsetY: true }">
												<template v-slot:item="{ item }">
													<v-row
														dense
														class="text-body-2 d-flex justify-space-between">
														{{ item.website }}
														<v-chip>ID: {{ item.casino_id }}</v-chip>
													</v-row>
												</template>

												<template v-slot:selection="{ item, index }">
													<v-chip
														small
														close
														:key="`gameKey-${item.casino_id}`"
														@click:close="removeElement(index)"
														v-if="noShorten || index < maxChips">
														<span>{{ item.website }}</span>
													</v-chip>
													<span
														v-if="!noShorten && index === maxChips"
														class="grey--text text-caption">
														(+{{ selected_Casinos.length - maxChips }}
														{{
															selected_Casinos.length - maxChips > 1
																? "others"
																: "other"
														}})
													</span>
												</template>

												<template v-slot:prepend-item>
													<div
														v-ripple
														:class="
															selected_Casinos.length === allCasinos.length
																? ''
																: 'primary--text'
														"
														class="selectall px-3 text-body-2 d-flex align-center justify-space-between"
														@mousedown.prevent
														@click="toggleAllCasinos">
														{{
															selected_Casinos.length === allCasinos.length
																? "Deselect All"
																: "Select All"
														}}
													</div>
												</template>
											</v-autocomplete>
										</div>

										<v-card-title>Allowed Sections:</v-card-title>
										<v-card-text>
											<user-edit-sections
												:readonly="false"
												@change="
													(newVal) => {
														this.selected_Sections = newVal;
													}
												"></user-edit-sections>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-row justify="center">
							<v-btn
								color="primary"
								@click="onSubmit">
								Submit
							</v-btn>
						</v-row>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import cardHeading from "../../components/shared/CardHeading.vue";
import UserEditSections from "../../components/users/UserEditSections.vue";

export default {
	components: {
		cardHeading,
		UserEditSections,
	},
	data() {
		return {
			email: "",
			firstName: "",
			lastName: "",
			phoneNumber: "",
			password: "",
			isAdmin: false,
			valid: true,
			show1: false,
			show2: false,
			maxChips: 5,
			noShorten: false,
			rules: {
				required: (v) => !!v || "This field is required",
				min: (v) => v.length >= 8 || "Min 8 characters",
				email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
				passwordMatch: (v) =>
					v == this.password || "Password you entered doesn't match",
			},
			allCasinos: [],
			selected_Casinos: [],
			selected_Sections: {},
		};
	},
	computed: {
		...mapGetters(["casinoList", "sectionList"]),
		selectedCasinos() {
			if (!this.allCasinos) {
				return this.allCasinos
					.filter((casino) => casino.checked)
					.map((casino) => casino.website);
			} else {
				return [];
			}
		},
	},
	methods: {
		...mapActions("notification", ["setMessage"]),
		...mapActions(["getCasinoList", "createUser"]),
		toggleAllCasinos() {
			if (this.selected_Casinos.length == this.allCasinos?.length) {
				this.selected_Casinos = [];
			} else {
				this.selected_Casinos = this.allCasinos ?? this.selected_Casinos;
			}
		},
		allCasinosSelected() {
			return this.allCasinos?.length == this.selected_Casinos.length
				? "Deselect all"
				: "Select all";
		},
		handleRemoveChip(casinoID) {
			this.selected_Casinos.splice(
				this.selected_Casinos.findIndex((casino) => casino == casinoID),
				1
			);
		},
		selectAllCasinos() {
			this.selected_Casinos = this.allCasinos ?? this.selected_Casinos;
		},
		filterByIdOrName(obj, query) {
			return (
				obj.website.includes(query) || obj.casino_id.toString().includes(query)
			);
		},
		async onSubmit() {
			if (this.$refs.form.validate()) {
				await this.createUser({
					email: this.email,
					password: this.password,
					firstName: this.firstName,
					lastName: this.lastName,
					phoneNumber: this.phoneNumber,
					allowedCasinos: this.selected_Casinos,
					allowedSections: { logan: this.selected_Sections },
					isAdmin: this.isAdmin,
				});
				this.$router.push({ name: "user-management" });
			}
		},
	},
	async created() {
		this.getCasinoList().then(() => {
			this.allCasinos = this.casinoList?.data;
		});
	},
};
</script>
